<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title> Компании </b-card-title>
      </b-card-header>

      <b-table-simple class="table-striped">
        <b-thead>
          <b-tr>
            <b-td class="text-black font-weight-bolder">#</b-td>
            <b-td class="text-black font-weight-bolder">Название</b-td>
            <b-td></b-td>
            <b-td></b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-if="items.length === 0">
            <b-td colspan="3" class="text-center">
              <span class="text-muted">Нет компаний</span>
            </b-td>
          </b-tr>
          <b-tr v-for="(item, index) in items" :key="index">
            <template v-if="item.is_edit && editable[item.id]">
              <b-td>{{ item.id }}</b-td>
              <b-td colspan="1">
                <b-form-input
                  v-model="editable[item.id].name"
                  placeholder="Название компании"
                ></b-form-input>
              </b-td>
              <b-td colspan="2">
                <b-col
                  v-if="item.medias.length === 0"
                  @click="preferMediaPickerAction(index, 'edit')"
                  class="d-flex justify-content-center align-items-center flex-nowrap bg-gray"
                  style="
                    padding: 12px;
                    height: 175px;
                    cursor: pointer;
                    border-radius: 4px;
                    margin: 0 12px;
                  "
                >
                <p class="text-muted">
                  Кликните, чтобы добавить медиа объект к публикации <br />
                  Разрешение 148х62
                </p>
                </b-col>

                <b-col
                  v-else
                  @click="preferMediaPickerAction(index, 'edit')"
                  class="d-flex justify-content-start align-items-center flex-nowrap bg-gray"
                  style="
                    padding: 12px;
                    height: 175px;
                    cursor: pointer;
                    border-radius: 4px;
                    margin: 0 12px;
                    overflow-y: hidden;
                    overflow-x: scroll;
                  "
                >
                  <div
                    v-for="(media, index_m) in item.medias"
                    :key="index_m"
                    @click="$event.stopPropagation()"
                    :style="getMediaPlaceholderStyle(media)"
                  >
                    <div
                      @click="prefereMediaDeleteAction($event, index, 'edit')"
                      class="bg-white"
                      style="
                        position: absolute;
                        text-align: center;
                        line-height: 30px;
                        pointer: cursor;
                        right: -10px;
                        top: -10px;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                      "
                    >
                      <feather-icon icon="XIcon" size="20" color="gray" />
                    </div>

                    <b-progress
                      v-if="media.id < 0"
                      :value="media.progress.loaded"
                      :max="media.progress.total"
                      show-progress
                      animated
                      style="width: 100%"
                    ></b-progress>
                  </div>
                </b-col>
              </b-td>
              <b-td class="text-right">
                <b-link @click="update(item)">
                  <feather-icon
                    icon="CheckIcon"
                    class="text-success"
                    size="15"
                  />
                </b-link>
                <b-link @click="setEditMode(item, false)" class="ml-1">
                  <feather-icon icon="XIcon" class="text-danger" size="15" />
                </b-link>
              </b-td>
            </template>
            <template v-else>
              <b-td class="text-black">{{ item.id }}</b-td>
              <b-td class="text-black">{{ item.name }}</b-td>
              <b-td class="text-center" colspan="2">
                <b-link @click="setEditMode(item, true)">
                  <feather-icon
                    icon="EditIcon"
                    class="text-primary"
                    size="15"
                  />
                </b-link>
                <b-link
                  @click="remove(item, index)"
                  v-b-tooltip.hover
                  title="Удалить"
                  class="ml-1"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger"
                    size="15"
                  />
                </b-link>
              </b-td>
            </template>
          </b-tr>
          <b-tr v-if="isOnModuleAddState">
            <b-td>
              <b-form-group class="form-group-with-label">
                <validation-provider #default="{ errors }" rules="required">
                  <span
                    :class="{
                      'text-muted': errors.length > 0 ? false : true,
                      'font-weight-bolder': errors.length > 0 ? true : false,
                      'text-danger': errors.length > 0 ? true : false,
                    }"
                    style="font-size: 12px"
                    >Название</span
                  >
                  <b-form-input id="name" v-model="name" />
                </validation-provider>
              </b-form-group>
            </b-td>
            <b-td>
              <b-col
                v-if="medias.length === 0"
                @click="preferMediaPickerAction(-1, 'add')"
                class="d-flex justify-content-center align-items-center flex-nowrap bg-gray"
                style="
                  padding: 12px;
                  height: 175px;
                  cursor: pointer;
                  border-radius: 4px;
                  margin: 0 12px;
                "
              >
                <p class="text-muted">
                  Кликните, чтобы добавить медиа объект к публикации <br />
                  Разрешение 147х62
                </p>
              </b-col>

              <b-col
                v-else
                @click="preferMediaPickerAction(-1, 'add')"
                class="d-flex justify-content-start align-items-center flex-nowrap bg-gray"
                style="
                  padding: 12px;
                  height: 175px;
                  cursor: pointer;
                  border-radius: 4px;
                  margin: 0 12px;
                  overflow-y: hidden;
                  overflow-x: scroll;
                "
              >
                <div
                  v-for="(media, index) in medias"
                  :key="index"
                  @click="$event.stopPropagation()"
                  :style="getMediaPlaceholderStyle(media)"
                >
                  <div
                    @click="prefereMediaDeleteAction($event, index, 'add')"
                    class="bg-white"
                    style="
                      position: absolute;
                      text-align: center;
                      line-height: 30px;
                      pointer: cursor;
                      right: -10px;
                      top: -10px;
                      width: 32px;
                      height: 32px;
                      border-radius: 50%;
                    "
                  >
                    <feather-icon icon="XIcon" size="20" color="gray" />
                  </div>

                  <b-progress
                    v-if="media.id < 0"
                    :value="media.progress.loaded"
                    :max="media.progress.total"
                    show-progress
                    animated
                    style="width: 100%"
                  ></b-progress>
                </div>
              </b-col>
            </b-td>
            <b-td>
              <b-link
                v-b-tooltip.hover
                title="Добавить"
                class="mr-1"
                @click="add"
              >
                <feather-icon icon="CheckIcon" class="text-success" size="22" />
              </b-link>
              <b-link
                @click="isOnModuleAddState = false"
                v-b-tooltip.hover
                title="Отменить"
              >
                <feather-icon icon="XIcon" class="text-danger" size="22" />
              </b-link>
            </b-td>
          </b-tr>
          <b-tr v-if="!isOnModuleAddState">
            <b-td colspan="6">
              <b-link @click="isOnModuleAddState = true">
                Добавить компанию
              </b-link>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import App from "@/modules/contents/app";
import { pickFile } from "js-pick-file";
import $utils from "@/modules/utils/";

export default {
  data() {
    return {
      App,
      items: [],
      isOnModuleAddState: false,
      name: "",
      medias: [],
      editable: {},
    };
  },
  methods: {
    remove(item, index) {
      this.$swal({
        title: `Подтвердите действие`,
        text: `Вы действительно хотите удалить компанию '${item.name}'?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Удалить",
        cancelButtonText: "Отмена",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-secondary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$request
            .post("companies/delete", {
              id: item.id,
            })
            .then((rsp) => {
              this.items.splice(index, 1);
              this.$swal({
                icon: "success",
                title: item.name,
                text: "Компания успешно удалена",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    add(args) {
      let medias = this.medias.map((media) => media.id);
      this.$request
        .post("companies/add", {
          name: this.name,
          medias: medias,
        })
        .then((rsp) => {
          this.items = rsp;
          this.name = "";
          this.medias = [];
          this.isOnModuleAddState = false;
        });
    },

    setEditMode(item, val) {
      if (val) {
        this.$set(this.editable, item.id, {
          id: item.id,
          name: item.name,
          medias: item.medias || null,
        });
      }

      this.$set(item, "is_edit", val);
    },

    update(company) {
      let medias = this.medias.map((media) => media.id);
      this.$request
        .post("companies/update", {
          id: company.id,
          name: this.editable[company.id].name,
          medias: medias,
        })
        .then((rsp) => {
          company.name = this.editable[company.id].name;
          this.setEditMode(company, false);
        });
    },

    getMediaPlaceholderStyle(media) {
      return {
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "125px",
        minWidth: "125px",
        height: "125px",
        backgroundImage: `url(${media.thumb || media.urls.thumb})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        borderRadius: "12px",
        margin: "0 8px",
        padding: "0 8px",
      };
    },

    prefereMediaDeleteAction(args, index, action) {
      if (action == "add") this.medias.splice(index, 1);
      else if (action == "edit") this.items[index].medias = [];

      args.stopPropagation();
    },

    preferMediaPickerAction(index, action) {
      let options = {
        accept: ".jpg, .jpeg, .png",
        multiple: true,
      };

      pickFile(options).then((files) => {
        files.forEach((file) => {
          $utils.preferBeforeUploadAction(file).then((data) => {
            let object = {
              id: -1,
              type: data.type,
              file: file,
              fileName: file.name,
              size: file.size,
              extension: file.name.split(".").pop(),
              duration: data.duration,
              thumb: data.thumb,
              placeholder: data.placeholder,
              width: data.width,
              height: data.height,
              progress: {
                loaded: 0,
                total: 0,
              },
            };

            if (action == "edit") {
              this.items[index].medias = [];
              this.items[index].medias.push(object);
            }

            this.medias = [];
            this.medias.push(object);

            this.$request
              .transfer(
                "companies.media.upload",
                {
                  type: data.type,
                  width: data.width,
                  height: data.height,
                },
                file,
                (progress) => {
                  object.progress.total = progress.total;
                  object.progress.loaded = progress.loaded;
                }
              )
              .then((rsp) => {
                this.$set(object, "id", rsp.upload_id);
                //                                object.id = rsp.upload_id;
              });
          });
        });
      });
    },
  },
  computed: {},
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  watch: {},
  mounted() {
    this.is_loading = true;

    this.$request.get("companies/list").then((rsp) => {
      this.items = rsp;
      this.is_loading = false;
    });
  },
};
</script>
